
<template>
  <v-dialog 
    v-model="showDialog" 
    persistent width="400px"
  >
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ editEmergencia ? 'Editar emergencia' : 'Añadir emergencia' }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="dialog-content">
        <v-form v-model="isValid">
          <v-container>
            <v-row>
              <v-col>
                <v-select 
                  v-model="emergenciaActiva" 
                  :items="posiblesEstados" 
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Estado" 
                  dense outlined 
                  item-text="text" 
                  item-value="value" 
                  placeholder="Estado"
                  autocomplete hide-details
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col sm="6">
                <v-text-field 
                  v-model="emergencia.latitude" 
                  label="Latitud*" 
                  dense 
                  outlined
                  :rules="[globalRules.required]"
                  prepend-icon="mdi-map-marker" 
                  @blur="updatedLatLon()" 
                  v-mask="'##.#####'" hide-details
                />
              </v-col>

              <v-col sm="5">
                <v-text-field 
                  v-model="emergencia.longitude" 
                  label="Longitud*" 
                  dense outlined
                  @blur="updatedLatLon()" 
                  v-mask="'-#.#####'" 
                  hide-details
                  :rules="[globalRules.required]"
                />
              </v-col>

              <v-col sm="1">
                <v-btn
                  icon color="blue" 
                  @click="editLocation()"
                >
                  <v-icon>mdi-map-marker-star</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col sm="6" v-show="xETRS89">
                <v-text-field 
                  v-model="xETRS89" 
                  label="X" 
                  dense outlined prepend-icon="mdi-map-marker" 
                  @blur="updatedXY()" 
                  v-mask="'######'" 
                  hide-details
                  disabled
                />
              </v-col>

              <v-col sm="5" v-show="yETRS89">
                <v-text-field 
                  v-model="yETRS89" 
                  label="Y" 
                  dense outlined @blur="updatedXY()" 
                  v-mask="'#######'" 
                  hide-details
                  disabled
                />
              </v-col>
            </v-row>

            <v-row v-if="distrito">
              <v-col>
                <v-text-field 
                  v-model="distrito" 
                  label="Distrito" 
                  placeholder="Distrito" 
                  hide-details disabled
                />
              </v-col>
            </v-row>

            <v-row v-if="barrio">
              <v-col>
                <v-text-field 
                  v-model="barrio" 
                  label="Barrio" 
                  placeholder="Barrio" 
                  hide-details disabled
                />
              </v-col>
            </v-row>
            <v-row v-if="address">
              <v-col>
                <v-text-field 
                  v-model="address" 
                  label="Calle" 
                  placeholder="Calle" 
                  prepend-icon="mdi-road-variant" 
                  hide-details disabled
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field 
                  v-model="emergencia.nombre" 
                  label="Nombre*" 
                  dense
                  outlined
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <vx-date-text-edit 
                  v-model="fechaSelected" 
                  label="Fecha de inicio*" 
                  not-future outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- Fecha de activacion de la emergencia -->
                <v-text-field
                  v-model="fechaActivation"
                  type="datetime-local"
                  @blur="fechaActivacion = $event.value"
                  label="Fecha de activación"
                  placeholder="DD/MM/YYYY HH:mm"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea 
                  v-model="emergencia.descripcion" 
                  label="Descripcion" 
                  dense outlined 
                  placeholder="Descripcion" 
                  auto-grow hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select 
                  v-model="emergencia.tipo" 
                  :items="tiposEmergencia" 
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Tipo*" 
                  dense outlined 
                  item-text="nombre" 
                  item-value="id" 
                  placeholder="Tipo Emergencia" 
                  autocomplete hide-details
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>
            <v-row v-if="!editEmergencia">
              <v-col>
                <v-select 
                  v-model="faseSelected" 
                  :items="fasesEmergencia" 
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Fase*" 
                  dense outlined 
                  item-text="nombre" 
                  item-value="id" 
                  placeholder="Fase"
                  :rules="[globalRules.required]"
                  autocomplete hide-details
                  return-object
                />
              </v-col>
            </v-row>
            <v-row
              v-if="situacionesOperativas && situacionesOperativas.length > 0 && !editEmergencia"
            >
              <v-col>
                <v-select
                  v-model="situacionOperativa.id" 
                  :items="situacionesOperativas" 
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Situacion Operativa*" 
                  dense 
                  outlined 
                  item-text="nombre" 
                  item-value="id"
                  placeholder="Situacion Operativa"
                  :rules="[globalRules.required]"
                  autocomplete 
                  hide-details
                />
              </v-col>
            </v-row>
            <!-- Fecha de declaracion de la situacion operativa -->
            <!-- <v-row v-if="situacionOperativa.id || editEmergencia" class="mt-3"> -->
            <!-- <v-col>
                <v-menu 
                  ref="fechaDeclaracion" 
                  v-model="showFechaDeclaracion" 
                  :close-on-content-click="false" 
                  :nudge-right="40" 
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field 
                      :value="fechaDeclaracionFormatted" 
                      @click:clear="situacionOperativa.fechaDeclaracion = null" 
                      label="Fecha de declaracion" 
                      prepend-icon="esri-icon-calendar" readonly 
                      v-bind="attrs" 
                      v-on="on" outlined dense clearable hide-details
                      :rules="[globalRules.required]"
                    />
                  </template>
                  <v-date-picker 
                    v-model="situacionOperativa.fechaDeclaracion" 
                    first-day-of-week="1"
                    @input="$refs.fechaDeclaracion.save(cerrarCalendario)" 
                    locale="es-ES"
                  />
                </v-menu>
              </v-col> -->

            <!-- Hora de declaracion de la situacion operativa -->
            <!-- <v-col>
                <v-text-field
                  v-model="situacionOperativa.horaDeclaracion"
                  type="time"
                  label="Hora de declaración"
                  prepend-icon="esri-icon-time-clock"
                  dense
                  outlined
                />
              </v-col> -->
            <!-- </v-row> -->
            <v-row v-if="situacionOperativa.id || editEmergencia">
              <v-col>
                <v-select
                  v-model="tipoRiesgoSelected"
                  label="Riesgos"
                  :items="tiposRiesgo"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  placeholder="Riesgos"
                  return-object
                  multiple
                  dense outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar()">Cancelar</v-btn>
        <v-btn
          text color="green darken-1" 
          :disabled="!isValid" 
          @click="aceptar()"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import * as ArcGIS from '@/helpers/ArcGIS'
import {EmergenciaRequest} from '@/api/emergencia'
import {TipoRiesgoRequest} from '@/api/tipoRiesgo'
import {SubriesgoRequest} from '@/api/subRiesgo'
import {FaseEmergenciaRequest} from '@/api/faseEmergencia'
import {SituacionOperativaRequest} from '@/api/situacionOperativa'
import {TipoEmergenciaRequest} from '@/api/tipoEmergencia'
import dateService from '@/helpers/DateService'
import { Emergencia } from '@/models/Emergencia'
import { convertirLatLonToEPSG3857 } from '@/helpers/ArcGIS'
import {getPointBarrioDistrito} from '@/helpers/MunicipioAPI'
import { EmergenciaArcGISHelper } from '@/helpers/ArcGISHelpers/EmergenciaArcGISHelper'
import api from '@/api'

export default {
  props: {
    editEmergencia: {
      type: Object,
      default: null
    },
    coords: {
      type: Object
    },
    show: Boolean
  },

  data: () => ({
    showDialog: false,
    showSubriesgo: false,
    // showFechaDeclaracion: false,
    showFechaActivation: false,

    isValid: true,
    notifyTipoEmergencia: false,

    distrito: null,
    barrio: null,
    address: null,
    xETRS89: null,
    yETRS89: null,

    faseSelected: null,
    fechaSelected: null,
    emergenciaActiva: 1,
    fechaActivation: null,

    emergencia: {
      activa: true,
      nombre: null,
      descripcion: null,
      tipoRiesgoSelected: null,
      subriesgoSelected: null,
      fase: null,
      situacionOperativa: null,
      latitude: null,
      longitude: null,
      tipo: null,
      usuariostamp: null
    },

    situacionOperativa: {
      // fechaDeclaracion: null,
      id: null,
      // horaDeclaracion: null,
      // tiene_hora: false
    },

    posiblesEstados: [
      {
        value: 0,
        text: 'Inactiva'
      },
      {
        value: 1,
        text: 'Activa'
      }
    ],

    tipoRiesgoSelected: null,

    tiposRiesgo: [],
    subriesgos: [],
    situacionesOperativas: [],
    tiposEmergencia: [],
    isShowNewCoordenadas: false,
  }),

  async mounted (){
    if (this.editEmergencia) {
      this.emergencia = {...this.editEmergencia}
    }
    this.emergencia.usuariostamp = this.currentUsuario.ID
    await this.$store.dispatch('emergencia/fetchFasesEmergencia')
    this.tiposEmergencia = this.getTiposEmergencia()
    this.tiposRiesgo = await TipoRiesgoRequest.getAll()
  },

  computed: {

    ...mapGetters('usuario', [
      'currentUsuario'
    ]),

    ...mapGetters('map', [
      'gettingNewCoordenadas',
      'newCoordenadas'
    ]),

    ...mapState('emergencia', [
      'emergenciaSelected',
      'fasesEmergencia'
    ]),

    fechaActivationFormatted () {
      return this.formatDate(this.fechaActivation)
    },

    // fechaDeclaracionFormatted () {
    //   return this.formatDate(this.situacionOperativa.fechaDeclaracion)
    // },
  },

  watch: {
    async show () {
      this.showDialog = this.show
      if (this.show) {
        this.tiposEmergencia = await TipoEmergenciaRequest.getAll()
        this.fechaSelected = this.$date.currentDate()
        if (this.coords){
          this.emergencia.latitude = this.coords.LATITUDE
          this.emergencia.longitude = this.coords.LONGITUDE
          this.getDataFromCoords(this.coords.LATITUDE, this.coords.LONGITUDE)
        } else {
          this.clearLocation()
        }
        if (this.editEmergencia){
          this.asignEditEmergenciaToEmergencia(this.editEmergencia)
        } else {
          this.emergencia.tipo = this.tiposEmergencia.length === 1 ? 
            this.tiposEmergencia[0].id :
            null
        }
      }
    },

    async faseSelected (newValue) {
      this.emergencia.situacionOperativa = null
      this.situacionOperativa.id = null
      this.situacionesOperativas = []
      if (newValue) {
        this.emergencia.fase = newValue.id
        this.situacionesOperativas = 
          await SituacionOperativaRequest.getByFaseEmergencia(newValue.id)
        if (this.situacionesOperativas.length == 1){
          this.situacionOperativa.id = this.situacionesOperativas[0].id
        }
      }
    },

    // async tipoRiesgoSelected (newValue){
    //   subriesgoSelected = null
    //   if (newValue){
    //     this.subriesgos = await SubriesgoRequest.getByTipoRiesgo(newValue)
    //   }
    // },

    gettingNewCoordenadas () {
      if (!this.gettingNewCoordenadas && this.isShowNewCoordenadas && this.show) { 
        this.showDialog = true
      }
    },

    newCoordenadas () {
      if (this.show) {
        this.distrito = this.newCoordenadas.DISTRITO
        this.barrio = this.newCoordenadas.BARRIO

        this.emergencia.latitude = this.newCoordenadas.LATITUD
        this.emergencia.longitude = this.newCoordenadas.LONGITUD

        this.xETRS89 = this.newCoordenadas.X.toFixed(2)
        this.yETRS89 = this.newCoordenadas.Y.toFixed(2)
        this.show = true
        this.isShowNewCoordenadas = false
      }
    },

    /**
     * @param {Emergencia} newValue 
     */
    async editEmergencia (newValue){
      if (newValue){
        this.asignEditEmergenciaToEmergencia(newValue)
      }
    }
  },

  methods: {

    ...mapActions('map', [
      'startNewCoordenadas'
    ]),

    formatDate (inputDate){
      let date = this.$date.parseDate(inputDate, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    },

    async asignEditEmergenciaToEmergencia (editEmergencia){
      // Comprobar si tiene tipo de emergencias
      const tieneTipoEmer = await this.comprobarTieneTipoEmergenciaEditEmergencia (editEmergencia)
      
      
      if (!tieneTipoEmer) {
        let newEditEmergencia = Object.assign({}, editEmergencia)
        newEditEmergencia.tipo = null
        this.emergencia = {...newEditEmergencia}
      } else {
        this.emergencia = {...Object.assign({}, editEmergencia)}
      }

      this.emergenciaActiva = editEmergencia.activa? 1 : 0
      try {
        this.faseSelected = await FaseEmergenciaRequest.getBySituacionOperativa(editEmergencia.situacionOperativa)
      } catch (err) {
        // Do nothing, se gestiona la excepcion en FaseEmergenciaRequest
      }
      this.fechaSelected = dateService.formatDateTime(editEmergencia.fechaCreacion)
      this.fechaActivation = this.$date.formatDate(editEmergencia.fechaActivacion, 'YYYY-MM-DD HH:mm')
      // Comprobar si tiene hora de declaracion
      // if (editEmergencia.tiene_hora) {
      //   // Asignar hora
      //   this.situacionOperativa.horaDeclaracion = await this.$date.formatDate(editEmergencia.fecha_declaracion, 'HH:mm')
      //   // Asignar fecha
      //   this.situacionOperativa.fechaDeclaracion = await this.$date.formatDate(editEmergencia.fecha_declaracion, 'YYYY-MM-DD')
      // } else {
      //   this.situacionOperativa.horaDeclaracion = null
      //   // Asignar fecha
      //   this.situacionOperativa.fechaDeclaracion = await this.$date.formatDate(editEmergencia.fecha_declaracion, 'YYYY-MM-DD')
        
      // }
      this.situacionOperativa.id = editEmergencia.SituacionOperativa
      this.getDataFromCoords(this.emergencia.latitude, this.emergencia.longitude)
      this.tipoRiesgoSelected = this.getEmergenciaRiesgos(editEmergencia)
    },

    clearLocation (){
      this.xETRS89 = null
      this.yETRS89 = null
      this.latitude = null
      this.longitude = null
      this.distrito = null
      this.barrio = null
      if (this.emergencia){
        this.emergencia.latitude = null
        this.emergencia.longitude = null
      }
    },

    clearData (){
      this.emergencia = {
        nombre: null,
        descripcion: null,
        tipoRiesgoSelected: null,
        subriesgoSelected: null,
        fase: null,
        situacionOperativa: null,
        tipo: null,
        usuariostamp: null
      }
      this.tipoRiesgoSelected = null
      this.clearLocation()
      this.faseSelected = null
      this.fechaSelected = null
      this.situacionOperativa.id = null
      // this.situacionOperativa.fechaDeclaracion = null
      // this.situacionOperativa.horaDeclaracion = null
      // this.situacionOperativa.tiene_hora = null
      this.fechaActivation = null
    },

    async getDataFromCoords (lat, long){
      const coords = convertirLatLonToEPSG3857(lat, long)
      const datosPosicion = await getPointBarrioDistrito(coords[0], coords[1])
      this.distrito = datosPosicion.DISTRITO
      this.barrio = datosPosicion.BARRIO
      this.xETRS89 = datosPosicion.X
      this.yETRS89 = datosPosicion.Y
    },

    editLocation () {
      this.showDialog = false
      this.isShowNewCoordenadas = true
      this.startNewCoordenadas()
    },

    updatedLatLon () {
      if (this.emergencia.latitude 
        && this.emergencia.longitude 
        && this.xETRS89 
        && this.yETRS89)
      {
        let xy = ArcGIS.convertirLatLonToETRS89(
          this.emergencia.latitude, 
          this.emergencia.longitude
        )
        this.xETRS89 = xy[0].toFixed(2)
        this.yETRS89 = xy[1].toFixed(2)
      }
    },

    updatedXY () {
      if (this.emergencia.latitude 
        && this.emergencia.longitude 
        && this.xETRS89 
        && this.yETRS89
      ) {
        let latLon = ArcGIS.convertirETRS89ToLatLon(this.xETRS89, this.yETRS89)
        this.emergencia.longitude = latLon[0].toFixed(5)
        this.emergencia.latitude = latLon[1].toFixed(5)
      }
    },

    cancelar () {
      this.clearData()
      this.$emit('cancelar')
      this.idTipoDeteccion = 5
      // this.emergencia = {}
      // this.situacionOperativa = {}
      this.emergencia.descripcion = null
      this.notifyTipoEmergencia = false
    },

    // fechaDeclaracionConHora () {
    //   return new Date(this.situacionOperativa.fechaDeclaracion + ' ' + this.situacionOperativa.horaDeclaracion)
    // },

    async crearEmergencia (emergencia){
      try {
        const emergenciaCreada = await EmergenciaRequest.addEmergencia(emergencia)
        if (emergenciaCreada){
          this.$emit('nuevaEmergenciaCreada', emergenciaCreada)
          this.clearData()
          this.$notify({
            title: 'Emergencia creada',
            text: `Se ha creado la emergencia ${emergenciaCreada.nombre} correctamente`
          })
          // Se añaden a la capa de Emergencias todas las emergencias creadas (activas/inactivas)
          EmergenciaArcGISHelper.addEmergencia(emergenciaCreada)
        } else {
          this.lanzarNotificacionEmergenciaNoCreada()
        }
      } catch (error) {
        console.error(error)
        this.lanzarNotificacionEmergenciaNoCreada()
      }
    },

    emergenciaHaCambiadoEstado (){
      return this.editEmergencia.activa != Boolean(this.emergenciaActiva)
    },

    lanzarNotificacionEmergenciaNoCreada () {
      this.$notify({
        title: 'Emergencia no insertada',
        text: 'Ha ocurrido un error desconocido al introducir la emergencia',
        type: 'error'
      })
    },

    async checkDocumentosAsociadosAndUpdate (emergencia){
      const documentosEmergencia = await EmergenciaRequest.getDocumentos(emergencia.id)
      console.log('Documentos emergencia',documentosEmergencia)
      if (documentosEmergencia && documentosEmergencia.length > 0 && this.emergenciaHaCambiadoEstado()){
        const msg = this.editEmergencia.activa ? 
          'La emergencia tiene documentos asociados, si la desactiva, los documentos asociados se archivaran. ¿Desea continuar?' :
          'La emergencia tiene documentos asociados, si la activa, los documentos asociados se desarchivaran. ¿Desea continuar?'
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.updateEmergencia(emergencia)
          }
        })
      } else {
        this.updateEmergencia(emergencia)
      }
    },

    async updateEmergencia (emergencia){
      const changedLocation = emergencia.latitude != this.editEmergencia.latitude 
        || emergencia.longitude != this.editEmergencia.longitude
      const changedName = emergencia.nombre != this.editEmergencia.nombre
      EmergenciaRequest.update(emergencia).then((updatedEmergencia) => {
        if (updatedEmergencia){
          
          if (this.emergenciaHaCambiadoEstado()){
            if (this.emergencia.activa){
              EmergenciaArcGISHelper.updateEmergencia(updatedEmergencia)
            } else {
              EmergenciaArcGISHelper.updateEmergencia(updatedEmergencia)
            }
          } else {
            if (changedLocation || changedName) {
              EmergenciaArcGISHelper.updateEmergencia(updatedEmergencia)
            }
          }
          this.$notify({
            title: 'Emergencia editada',
            text: `La emergencia ${emergencia.nombre} ha sido editada correctamente`
          })
          this.$emit('emergenciaEdited', emergencia)
          this.clearData()
        }
      }).catch((error) => {
        this.$notify({
          title: 'Emergencia no modificada',
          text: 'Ha ocurrido un error desconocido al modificar la emergencia',
          type: 'error'
        })
      })
    },


    aceptar () {
      this.emergencia.activa = Boolean(this.emergenciaActiva)
      this.emergencia.fechaCreacion = dateService.parseDate(this.fechaSelected)
      this.emergencia.usuariostamp = this.currentUsuario.ID
      this.emergencia.fechaActivacion = dateService.parseDate(this.fechaActivation)
      this.emergencia.TipoRiesgos = this.tipoRiesgoSelected
      if (!this.editEmergencia){
        this.emergencia.tiene_hora = true
        this.emergencia.situacionOperativa = this.situacionOperativa.id
        this.notifyTipoEmergencia = false
        this.crearEmergencia(this.emergencia)
      } else {
        this.notifyTipoEmergencia = false
        this.checkDocumentosAsociadosAndUpdate(this.emergencia)
      }
    },

    getEmergenciaRiesgos (editEmergencia) {
      if (editEmergencia.TipoRiesgos) {
        if (editEmergencia.TipoRiesgos.length) {
          return JSON.parse(JSON.stringify(editEmergencia.TipoRiesgos))
        }
      }
    },

    async getTiposEmergencia () {
      if (!this.tiposEmergencia || this.tiposEmergencia.length === 0) {
        this.tiposEmergencia = await TipoEmergenciaRequest.getAll()
      } 
      return this.tiposEmergencia
    },

    comprobarTieneTipoEmergenciaEditEmergencia (editEmergencia) {
      let tipoEmergenciaDeleteOrNotExist = this.tiposEmergencia.find(x => x.id === editEmergencia.tipo)
      if (!tipoEmergenciaDeleteOrNotExist) {
        this.notificarTipoEmergenciaDeleteOrNotExiste()
        return false
      } else {
        return true
      }
    },

    notificarTipoEmergenciaDeleteOrNotExiste () {
      !this.notifyTipoEmergencia && this.$notify({
        title: 'Tipo de emergencia no disponible',
        text: 'Es necesario hacer un cambio de tipo de emergencia para poder actualizar la emergencia',
        type: 'error'
      })
      this.notifyTipoEmergencia = true
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-content {
  max-height: 59vh;
  overflow: auto;
  .v-form {
    margin-top: 1rem;
  }
}
</style>
