<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row class="mt-1">
              <v-col>
                <!-- Nombre departamento -->
                <v-text-field label="Departamento*" v-model="nombre" dense :rules="[globalRules.required]" maxlenght="50" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean,
    editedDelegacion: Object,
    editedDepartamento: Object,
    isEditDepartamento: Boolean,
    newDepartamento: Object
  },

  data: () => ({
    isValid: false,

    departamento: null,
    nombre: null
  }),

  watch: {
    show () {
      if (this.show) {
        // console.log('Delegacion: ', this.editedDelegacion)
        if (this.isEditDepartamento) {
          // Cargar nombre del departamento en el textfield
          this.departamento = this.editedDepartamento

          // Nombre
          this.nombre = this.editedDepartamento.nombre
        }
      }
    }
  },

  computed: {
    accionDialog () {
      return this.isEditDepartamento ? 'Editar departamento' : 'Nuevo departamento'
    },
    ...mapGetters('usuario', [
      'currentUsuario'
    ])
  },

  methods: {
    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    aceptar () {

      let delegacion = !this.isEditDepartamento ? JSON.parse(JSON.stringify(this.newDepartamento)) : {}

      let departamento = this.isEditDepartamento ? JSON.parse(JSON.stringify(this.editedDepartamento)) : {}

      // Editar departamento
      if (this.isEditDepartamento) {
        // id departamento
        departamento.id_departamento = this.editedDepartamento.id_departamento

        // id delegacion
        departamento.id_delegacion = this.editedDepartamento.id_delegacion

        // Nombre
        departamento.nombre = this.nombre

        // is default
        departamento.is_default = this.editedDepartamento.is_default

        // Usuariostamp
        departamento.usuariostamp = this.currentUsuario.ID

        // Borrado
        departamento.borrado = this.editedDepartamento.borrado

        // Unidad_medio
        departamento.Unidad_medios = this.editedDepartamento.Unidad_medios

        // Usuario
        departamento.Usuario = {
          nombre: this.currentUsuario.NOMBRE,
          apellidos: this.currentUsuario.APELLIDOS
        }

        this.$emit('editDepartamento', departamento)
        this.$refs.form.reset()
      } else {
        // Nuevo departamento
        departamento.id_departamento = this.$uuid.createUUID()
        departamento.nombre = this.nombre
        departamento.id_delegacion = delegacion.id_delegacion
        departamento.is_default = false
        departamento.usuariostamp = this.currentUsuario.ID
        departamento.borrado = false

        // UnidadMedio
        departamento.Unidad_medios = []

        // Usuario
        departamento.Usuario = {
          nombre: this.currentUsuario.NOMBRE,
          apellidos: this.currentUsuario.APELLIDOS
        }

        this.$emit('addDepartamento', delegacion, departamento)
        this.$refs.form.reset()
      }
    }
  }
}
</script>

<style scoped>

</style>