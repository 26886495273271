<template>
  <div
    class="icon" :class="{
      'icon-circle': legendIcon.type === 'circle',
      'icon-line': legendIcon.type === 'simple-line',
      'icon-polygon': legendIcon.type === 'simple-fill'
    }"
    :style="`background-color: rgba(${legendIcon.color.r},${legendIcon.color.g},${legendIcon.color.b},${legendIcon.color.a})`"
  />
</template>

<script>
export default {
  name: 'LayerItemLegendIcon',
  props: {
    legendIcon: {
      type: Object,
      required: true
    }
  },
  mounted () {
    console.log('this.legendIcon', this.legendIcon)
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
  &.icon-polygon{
    border-radius: 0;
  }
  &.icon-circle{
    border-radius: 50%;
  }
  &.icon-line{
    border-radius: 0;
    height: 2px;
    width: 20px;
  }
}
</style>